import {
  Benefits,
  BreadcrumbsLocations,
  CityDropdown,
  getParsedData,
  HeroBanner,
  LocationMapAndDropdown,
  CityStateTemplate as GenericCityStateTemplate
} from '@ecomm/city-state-components'
import {
  convertPathToCityName,
  getMapCoordinates,
  locationDisplayName,
  regionContentId,
  validateDynamicCityUrl
} from '@ecomm/city-state-data'
import { contentfulRichTextSchema } from '@ecomm/contentful-schemas'
import { Footer } from '@ecomm/footer-components'
import { Header, useHeaderRedesignQuery } from '@ecomm/header'
import {
  type ComparisonTableProps,
  ComparisonTable,
  ConfidenceBar,
  ExpertSection,
  GuaranteeSection,
  QuoteWizardSection,
  transformToComparisonTableData
} from '@ecomm/scout'
import {
  type Region,
  getCityPageLink,
  getStatePageLink
} from '@ecomm/seo-locations'
import { AffirmBanner } from '@ecomm/shared-components'
import { CountryRedirectModal } from '@ecomm/shared-components'
import { useOdmonExperience } from '@ecomm/shared-ninetailed-odmon'
import { TrackingProvider } from '@ecomm/tracking'
import { SEO } from '@ecomm/utils'
import { type TypeOf, z } from '@simplisafe/ewok'
import classNames from 'classnames'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { lookup } from 'fp-ts/lib/Record'
import { type PageProps, graphql, navigate } from 'gatsby'
import { Suspense } from 'react'

import { useCityStatePageQuery, useStaticData } from '../queries/cityState'
import { useHeroBannerQuery } from '../queries/heroBannerQuery'
import useFooterQuery from '../queries/useFooterQuery'
import type { TemplateCity as City } from '@ecomm/city-state-data'

export type PageContext = {
  readonly cityName?: string
  readonly state: string
  readonly stateId?: string
  readonly lat?: number
  readonly lng?: number
  readonly region: Region
  readonly cities: readonly City[]
}

const regionDataSchema = z.object({
  benefitsText: z.object({
    text: contentfulRichTextSchema
  })
})
type RegionData = TypeOf<typeof regionDataSchema>

export type Props<T> = Pick<
  PageProps<T, PageContext, unknown>,
  'pageContext' | 'params'
> & {
  readonly data: RegionData
}

function Content<T>({
  pageContext: { cityName, state, stateId, cities, lat, lng, region },
  params
}: Props<T>) {
  const isOdmonVariant = useOdmonExperience().isVariant

  const cityNameDynamic = convertPathToCityName(
    pipe(
      O.fromNullable(params),
      O.chain(lookup('citypath')),
      O.getOrElse(() => '')
    )
  )

  const isDynamicCityPage = cityNameDynamic !== ''
  const city = isDynamicCityPage ? cityNameDynamic : cityName
  const isValidDynamicCityUrl = isDynamicCityPage
    ? validateDynamicCityUrl(cities, cityNameDynamic)
    : true
  !isValidDynamicCityUrl && navigate(getStatePageLink(state))
  const humanReadableLocation = locationDisplayName(state, stateId, city)
  const headerData = useHeaderRedesignQuery()
  const cityStatePageData = useCityStatePageQuery()
  const footerData = useFooterQuery()

  const heroBannerData = useHeroBannerQuery('637i03f3OnpIsqhv7Ll5vP')
  const {
    benefitsText,
    expertSectionAssetData,
    affirmData,
    confidenceBarData,
    guaranteeAsset,
    guaranteeCopy,
    comparisonTable
  } = useStaticData(regionContentId[region])
  const { bestSystemsData, dropdownRedirectorData } = getParsedData(
    cityStatePageData,
    cities
  )

  const comparisonTableData: ComparisonTableProps = isOdmonVariant
    ? transformToComparisonTableData(
        cityStatePageData,
        'comparisonTableOdmonVariant'
      )
    : transformToComparisonTableData(comparisonTable.content)

  const mapCoordinates = getMapCoordinates(
    cities,
    isDynamicCityPage,
    cityNameDynamic,
    lat,
    lng
  )

  return (
    <TrackingProvider
      metaTitle={`Shop Home Security Systems & Packages for ${humanReadableLocation} | SimpliSafe`}
    >
      <SEO
        canonicalLink={
          cityName ? getCityPageLink(state, cityName) : getStatePageLink(state)
        }
        isLandingPage={true}
        isNofollow={isDynamicCityPage}
        isNoindex={isDynamicCityPage}
        lang="en-US"
        metaDescription={`Browse SimpliSafe home security systems and packages in ${humanReadableLocation}. Get setup in minutes and start protecting your home today.`}
        metaKeywords={[]}
        metaTitle={`Shop Home Security Systems & Packages for ${humanReadableLocation} | SimpliSafe`}
      />
      <GenericCityStateTemplate>
        {{
          Header: <Header {...headerData} />,
          Breadcrumbs: (
            <BreadcrumbsLocations
              city={city}
              isDynamicCityPage={isDynamicCityPage}
              state={state}
            />
          ),
          Content: (
            <main id="content">
              <div className="mx-auto mt-5 max-w-[1372px] lg:-mt-3">
                <HeroBanner
                  heroBannerProps={heroBannerData}
                  location={humanReadableLocation}
                />
              </div>

              <div
                className={classNames(
                  'prose mx-auto flex max-w-8xl flex-col gap-14 whitespace-pre-line px-4 pt-8 md:prose-md lg:prose-lg md:gap-16 md:px-8 lg:gap-20',
                  'mb-10 md:-mt-6 md:mb-16 lg:mb-20'
                )}
              >
                <ConfidenceBar data={confidenceBarData.content} />
                <AffirmBanner data={affirmData} />
              </div>
              {mapCoordinates.lng && mapCoordinates.lat ? (
                <LocationMapAndDropdown
                  bestSystemsData={{
                    buttons: bestSystemsData.buttons,
                    title: bestSystemsData.title.replace(
                      '{{location}}',
                      humanReadableLocation
                    ),
                    description: bestSystemsData.description[region].replace(
                      '{{location}}',
                      humanReadableLocation
                    )
                  }}
                  dropdownRedirectorData={city ? null : dropdownRedirectorData}
                  mapData={{
                    longitude: mapCoordinates.lng,
                    latitude: mapCoordinates.lat,
                    zoom: city ? 13 : 8
                  }}
                />
              ) : null}
              <div
                className={classNames(
                  'prose mx-auto flex max-w-8xl flex-col gap-14 whitespace-pre-line px-4 pt-8 md:prose-md lg:prose-lg md:gap-16 md:px-8 lg:gap-20',
                  'mb-14 mt-8 md:mb-20 md:mt-14 lg:mb-28 lg:mt-20'
                )}
              >
                <QuoteWizardSection
                  hideExtraTextOnMobile={false}
                  image={cityStatePageData.quoteWizardAsset}
                  quoteWizard={cityStatePageData.quoteWizard}
                />
                <Benefits
                  images={cityStatePageData.benefitsImages}
                  location={humanReadableLocation}
                  text={benefitsText}
                />
                <ComparisonTable data={comparisonTableData} />
                <GuaranteeSection
                  copy={guaranteeCopy}
                  image={guaranteeAsset.guaranteeAsset}
                  quoteWizard={cityStatePageData.quoteWizard}
                />
                <ExpertSection data={{ image: expertSectionAssetData }} />
                <CityDropdown
                  cities={cities}
                  groups={[
                    ['A', 'E'],
                    ['F', 'J'],
                    ['K', 'O'],
                    ['P', 'T'],
                    ['U', 'Z']
                  ]}
                  title="Select your City or Town"
                />
              </div>
            </main>
          ),
          Footer: <Footer {...footerData} />,
          Modal: <CountryRedirectModal />
        }}
      </GenericCityStateTemplate>
    </TrackingProvider>
  )
}

export default function CityStateTemplate<T>(props: Props<T>) {
  return (
    <Suspense fallback={null}>
      <Content {...props} />
    </Suspense>
  )
}

export const query = graphql`
  query CityStateTemplateRegionContent($regionContentId: String) {
    benefitsText: contentfulSmallText(contentful_id: { eq: $regionContentId }) {
      text {
        raw
      }
    }
  }
`
