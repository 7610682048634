import type { ReactNode } from 'react'

type Props = {
  readonly children: {
    readonly Header: ReactNode
    readonly Breadcrumbs: ReactNode
    readonly Footer: ReactNode
    readonly Content: ReactNode
    readonly Modal?: ReactNode
  }
}

export function CityStateTemplate({ children }: Props) {
  return (
    <>
      {children.Header}
      {children.Breadcrumbs}
      {children.Content}
      {children.Footer}
      {children.Modal}
    </>
  )
}
