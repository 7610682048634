export const formatLocationPath = (location: string): string =>
  location.toLowerCase().replaceAll(' ', '_').replace("'", '').replace('.', '')

export const getStatePageLink = (state: string): string =>
  `/location/${formatLocationPath(state)}`

export const getCityPageLink = (
  state: string,
  city: string,
  dynamicLink?: boolean
): string =>
  `${getStatePageLink(state)}/${
    dynamicLink === true ? 'dl/' : ''
  }${formatLocationPath(city)}`

export const locationPageLink = '/location'
