import { graphql, useStaticQuery } from 'gatsby'

export const useUpgrade2GSuccessPageQuery = () => {
  return useStaticQuery(graphql`
    #graphql
    query Upgrade2gSuccess {
      upgradeSuccess: contentfulJson(
        contentful_id: { eq: "3Av8IWIOlfyxd4xL9r7Gyj" }
      ) {
        ...json
      }
    }
  `)
}
