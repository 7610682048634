import { gql } from '@apollo/client/index.js'

export const CITY_STATE_TEMPLATE_REGION_CONTENT_QUERY = gql`
  query CityStateTemplateRegionContent(
    $regionContentId: String!,
    $preview: Boolean!
    $locale: String!) {
  text(id: $regionContentId, preview : $preview, locale : $locale) {
  	text {
      json
    }
  }

}`
