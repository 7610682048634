import { states } from '@ecomm/utils'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import useSubmit from '../../templates/Upgrade2gTemplate/useSubmit'
import InfoButton from '../Info/infoButton'
import Form2GSelectInput from './Form2GSelectInput'
import Form2GTextInput from './Form2GTextInput'
import { Form2GUpgradeSchema } from '@ecomm/home-landing-data'

type Upgrade2gFormProps = {
  readonly setShowInfoModal: (showInfoModal: boolean) => void
}

function Upgrade2gForm({ setShowInfoModal }: Upgrade2gFormProps) {
  const handleSubmission = useSubmit()

  const initialValues = {
    city: '',
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    sidNumber: '',
    state: '',
    streetAddress1: '',
    streetAddress2: '',
    zipCode: ''
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmission(values, setSubmitting)
      }}
      validationSchema={toFormikValidationSchema(Form2GUpgradeSchema)}
    >
      {({ errors, touched }) => (
        <Form className={'flex flex-col gap-4 md:m-2'}>
          <div className="flex flex-col gap-4 md:flex-row">
            <Form2GTextInput
              id="firstName"
              label="First Name"
              name="firstName"
              placeholder="First Name"
            />
            <Form2GTextInput
              id="lastName"
              label="Last Name"
              name="lastName"
              placeholder="Last Name"
            />
          </div>
          <Form2GTextInput
            id="streetAddress1"
            label="Street Address"
            name="streetAddress1"
            placeholder="Street Address"
          />
          <Form2GTextInput
            id="streetAddress2"
            label="Street Address 2"
            name="streetAddress2"
            placeholder="Street Address 2"
          />
          <div className="flex flex-col gap-4 md:flex-row">
            <Form2GTextInput
              id="city"
              label="City"
              name="city"
              placeholder="City"
            />
            <div className="flex flex-[2] gap-4">
              <Form2GTextInput
                id="zipCode"
                label="Zip Code"
                name="zipCode"
                placeholder="Zip Code"
              />
              <Form2GSelectInput
                id="state"
                label="State"
                name="state"
                options={states.map(s => s.name)}
                placeholder="State"
              />
            </div>
          </div>
          <Form2GTextInput
            id="phoneNumber"
            label="Phone"
            name="phoneNumber"
            placeholder="Phone Number"
          />
          <Form2GTextInput
            id="email"
            label="Email"
            name="email"
            placeholder="Email"
          />

          <div>
            <div className="flex items-end">
              <label
                className="prose text-neutral-black text-left font-medium"
                htmlFor={'sidNumber'}
              >
                Base Station Serial Number
              </label>
              <InfoButton onClick={() => setShowInfoModal(true)} />
            </div>
            <p className="my-2 text-sm">
              Your Base Station Serial Number is located on the bottom of the
              Base Station. Ex: xxxxxxx
            </p>
            <Field
              className={`placeholder:text-neutral-medium-100 text-body-size placeholder:font-default placeholder:text-body-size rounded-base h-14 w-full border border-solid p-4 ${
                errors.sidNumber && touched.sidNumber
                  ? 'border-sale'
                  : 'border-neutral-medium-100'
              }`}
              id="sidNumber"
              name="sidNumber"
              placeholder="7-Digit Base Station Serial Number"
            />
            <ErrorMessage
              className="text-sale mt-1"
              component={'div'}
              name="sidNumber"
            />
          </div>
          <div className="prose md:prose-md lg:prose-lg mt-4 max-w-none text-left">
            <button
              className="bg-btn-primary text-btn-accent border-btn-primary hover:bg-btn-dark w-auto cursor-pointer justify-center rounded-lg border-2 border-solid px-6 py-3 text-base md:text-lg"
              type="submit"
            >
              Submit
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Upgrade2gForm
