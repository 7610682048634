import {
  type ConfidenceBarProps,
  parseJSONDataFromContentful
} from '@ecomm/scout'
import type { Region } from '@ecomm/seo-locations'
import type { BestSystemsCTFLProps } from '../lib/BestSystems'
import type { DropdownRedirectorProps } from '../lib/DropdownRedirector'
import { LocationMapAndDropdown } from '../lib/LocationMapAndDropdown'
import type {
  TemplateCity as City,
  CityStatePage
} from '@ecomm/city-state-data'

export type BestSystemsSectionArgs = {
  readonly mapCoordinates: { readonly lat?: number; readonly lng?: number }
  readonly bestSystemsData: BestSystemsCTFLProps
  readonly city?: string
  readonly dropdownRedirectorData: DropdownRedirectorProps
  readonly region: Region
  readonly humanReadableLocation: string
}

export const renderBestSystemSection = ({
  mapCoordinates,
  bestSystemsData,
  city,
  dropdownRedirectorData,
  region,
  humanReadableLocation
}: BestSystemsSectionArgs) => {
  if (mapCoordinates.lng && mapCoordinates.lat) {
    return (
      <LocationMapAndDropdown
        bestSystemsData={{
          buttons: bestSystemsData.buttons,
          title: bestSystemsData.title.replace(
            '{{location}}',
            humanReadableLocation
          ),
          description: bestSystemsData.description[region].replace(
            '{{location}}',
            humanReadableLocation
          )
        }}
        dropdownRedirectorData={city ? null : dropdownRedirectorData}
        mapData={{
          longitude: mapCoordinates.lng,
          latitude: mapCoordinates.lat,
          zoom: city ? 13 : 8
        }}
      />
    )
  } else {
    return null
  }
}

export const getParsedData = (
  cityStatePageData: CityStatePage,
  cities: readonly City[]
) => {
  const confidenceBarData: ConfidenceBarProps = parseJSONDataFromContentful(
    cityStatePageData,
    'confidenceBar'
  )
  const bestSystemsData: BestSystemsCTFLProps = parseJSONDataFromContentful(
    cityStatePageData,
    'bestSystems'
  )
  const dropdownRedirectorData: DropdownRedirectorProps = {
    ...parseJSONDataFromContentful(cityStatePageData, 'dropdownRedirector'),
    links: cities
  }

  return {
    confidenceBarData,
    bestSystemsData,
    dropdownRedirectorData
  }
}
