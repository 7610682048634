import {
  v2ButtonSchema,
  v2ContentItemSchema,
  v2EmbeddedLinksSchema,
  v2GuidedSystemBuilderSchema,
  v2HeroBannerSchema
} from '@ecomm/shared-components'
import type { TypeOf } from '@simplisafe/ewok'

export const contentItemSchema = v2ContentItemSchema
export const embeddedLinksSchema = v2EmbeddedLinksSchema
export const buttonSchema = v2ButtonSchema
export const guidedSystemBuilderSchema = v2GuidedSystemBuilderSchema

export const heroBannerSchema = v2HeroBannerSchema

export type HeroBannerSchema = TypeOf<typeof heroBannerSchema> & {
  readonly className?: string
}

export type GuidedSystemBuilderSchema = TypeOf<typeof guidedSystemBuilderSchema>
export type HeroBannerColumnSchema = TypeOf<typeof contentItemSchema>
export type ButtonFragment = TypeOf<typeof buttonSchema>
export type EmbeddedLinksSchema = TypeOf<typeof embeddedLinksSchema>
