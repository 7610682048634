import { ErrorBoundary } from '@ecomm/error-handling'
import { useContentful } from '@ecomm/shared-apollo'
import { GRID_QUERY, gridSchema } from '@ecomm/shared-sections'
import { Suspense } from 'react'

import CardGrid from '.'

const useGatsbyGrid = (id: string) => {
  const { data } = useContentful(GRID_QUERY, { id })
  return gridSchema.parse(data.grid, {
    path: ['ProtectorsPage', 'CardGrid', 'useGatsbyGrid']
  })
}

type Props = {
  readonly id: string
}

function Content({ id }: Props) {
  const grid = useGatsbyGrid(id)

  return <CardGrid {...grid} />
}

export function GatsbyCardGrid(props: Props) {
  return (
    <Suspense>
      <ErrorBoundary>
        <Content {...props} />
      </ErrorBoundary>
    </Suspense>
  )
}
