import { fetchApi } from '@ecomm/data-simplisafe-api'
import { polyfillHasOwn } from '@ecomm/shared-polyfill'
import { pipe } from 'fp-ts/lib/function'
import * as TE from 'fp-ts/lib/TaskEither'

export type EmailFeedbackPayloadProps = {
  readonly campaignId: string
  readonly positiveFeedback: boolean
  readonly userComment: string
  readonly userId: number
}

type EmailFeedbackResponse = {
  readonly message: string
}

const isSubmitEmailFeedbackResponse = (
  res: unknown
): res is EmailFeedbackResponse => {
  polyfillHasOwn()
  return !!res && typeof res === 'object' && Object.hasOwn(res, 'message')
}

export function submitEmailFeedback(data: EmailFeedbackPayloadProps) {
  return pipe(
    TE.Do,
    TE.chain(() => {
      return fetchApi({
        method: 'POST',
        endpoint: '/leads/v1/feedback/email-feedback',
        headers: {},
        body: JSON.stringify(data)
      })
    }),
    TE.chain(res =>
      isSubmitEmailFeedbackResponse(res) ? TE.right(res) : TE.left(res)
    ),
    TE.mapLeft(res => Error(`Error fetching email-feedback method ${res}`))
  )()
}
