import { documentSchema } from '@ecomm/contentful-schemas'
import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import {
  affirmBannerSchema,
  guidedSystemBuilderSchema,
  heroBannerSchema as sharedHeroBannerSchema
} from '@ecomm/shared-components'
import { type TypeOf, z } from '@simplisafe/ewok'

const jsonSchema = z.object({
  content: z.object({
    internal: z.object({
      content: z.string()
    })
  })
})

const seoSchema = z.object({
  metaKeywords: z.string().array().nullish(),
  metaTitle: z.string(),
  isNoindex: z.boolean(),
  isNofollow: z.boolean(),
  canonicalLink: z.string(),
  metaDescription: z.object({
    metaDescription: z.string()
  })
})

export const benefitsTextSchema = z.object({
  text: z.object({
    json: documentSchema
  })
})

export type BenefitsText = TypeOf<typeof benefitsTextSchema>

export const benefitsSchema = z.object({
  images: z.object({
    nodes: gatsbyImageSchema.array()
  }),
  text: benefitsTextSchema
})

export type BenefitsSchema = TypeOf<typeof benefitsSchema>

export const cityStatePageSchema = z.object({
  confidenceBar: jsonSchema,
  contentfulAffirmBanner: affirmBannerSchema,
  bestSystems: jsonSchema,
  dropdownRedirector: jsonSchema,
  guaranteeCopy: jsonSchema,
  guaranteeAsset: gatsbyImageSchema,
  quoteWizard: guidedSystemBuilderSchema,
  expertSectionAsset: gatsbyImageSchema,
  comparisonTable: jsonSchema,
  comparisonTableOdmonVariant: jsonSchema,
  quoteWizardAsset: gatsbyImageSchema,
  benefitsImages: z.object({
    nodes: gatsbyImageSchema.array()
  })
})

export type CityStatePage = Zod.infer<typeof cityStatePageSchema>

export const locationPageSchema = z.object({
  heroBanner: sharedHeroBannerSchema,
  confidenceBar: jsonSchema,
  contentfulAffirmBanner: affirmBannerSchema,
  bestSystems: jsonSchema,
  guaranteeCopy: jsonSchema,
  guaranteeAsset: gatsbyImageSchema,
  quoteWizard: guidedSystemBuilderSchema,
  expertSectionAsset: gatsbyImageSchema,
  comparisonTable: jsonSchema,
  comparisonTableOdmonVariant: jsonSchema,
  quoteWizardAsset: gatsbyImageSchema,
  dropdownRedirector: jsonSchema,
  linksGrid: jsonSchema,
  seo: seoSchema
})
