import { Link as LinkComponent } from '@ecomm/framework'

export type Link = {
  readonly name: string
  readonly url: string
}

export type LinksGridProps = {
  readonly title: string
  readonly links: readonly Link[]
}

export function LinksGrid({ title, links }: LinksGridProps) {
  return (
    <div className="bg-neutral-light-50 px-8 pb-20 pt-3 md:pt-10 lg:px-40 lg:pt-6 min-[1650px]:px-80">
      <h2 className="font-arizona text-neutral-black mb-6 text-center text-[28px] font-bold lg:mb-10 lg:text-[55px]">
        {title}
      </h2>
      <div className="columns-2 break-words text-base font-bold leading-9 md:columns-3 lg:columns-4 lg:text-lg lg:font-normal lg:leading-9">
        {links.map((link, index) => (
          <LinkComponent
            className="text-neutral-black block hover:no-underline"
            key={index}
            to={link.url}
          >
            {link.name}
          </LinkComponent>
        ))}
      </div>
    </div>
  )
}
