import {
  CITY_STATE_TEMPLATE_REGION_CONTENT_QUERY,
  benefitsTextSchema
} from '@ecomm/city-state-data'
import { useContentful } from '@ecomm/shared-apollo'
import { useMemo } from 'react'

export const useBenefitsTextQuery = (region: string) => {
  const { data: rawApolloData } = useContentful(
    CITY_STATE_TEMPLATE_REGION_CONTENT_QUERY,
    {
      regionContentId: region
    }
  )

  return useMemo(
    () => benefitsTextSchema.parse(rawApolloData?.['text']),
    [rawApolloData]
  )
}
