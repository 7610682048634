import { getDynamicCityPageMapCoordinates } from './dynamicCityLinks'

export type TemplateCity = {
  readonly name: string
  readonly url: string
  readonly lat?: number
  readonly lng?: number
}

export const getMapCoordinates = (
  cities: readonly TemplateCity[],
  isDynamicCityPage: boolean,
  cityName?: string,
  lat?: number,
  lng?: number
) => {
  const mapCoordinatesDynamic =
    isDynamicCityPage && cityName
      ? getDynamicCityPageMapCoordinates(cities, cityName)
      : null
  return mapCoordinatesDynamic ? mapCoordinatesDynamic : { lat, lng }
}
