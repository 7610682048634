import useFragmentWithVariations from '../../hooks/useFragmentWithVariations'
import { mediaPageSchema, mediaPageTemplateSchema } from './schema'

export const useMediaPageFragment = <
  T extends { readonly contentfulMediaPageV2: U },
  U
>(
  query: T
) => {
  const mediaFragment = query.contentfulMediaPageV2

  const mediaPage = useFragmentWithVariations(mediaFragment, mediaPageSchema)

  const mediaTemplateFragment = mediaPage.mediaPageTemplate

  const mediaPageTemplate = useFragmentWithVariations(
    mediaTemplateFragment,
    mediaPageTemplateSchema
  )

  return {
    ...mediaPageSchema.parse(mediaPage),
    mediaPageTemplate: mediaPageTemplateSchema.parse(mediaPageTemplate)
  }
}
