import { ContentfulImage } from '@ecomm/contentful/components'
import { getContentfulImageFromGatsbyData } from '@ecomm/contentful-image-utils'
import { GatsbyImageSchema } from '@ecomm/contentful-schemas'
import { Modal } from '@ecomm/shared-components'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import React, { ReactNode } from 'react'

type InfoModalProps = {
  readonly isOpen: boolean
  readonly onClose: () => void
  readonly title: string
  readonly description: ReactNode
  readonly image?: O.Option<GatsbyImageSchema>
}

function InfoModal({
  isOpen,
  onClose,
  title,
  description,
  image = O.none
}: InfoModalProps) {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} size={'small'}>
      <div className="p-16">
        <div className="prose text-neutral-black whitespace-pre-line text-center">
          <p
            style={{
              fontSize: '36px',
              fontWeight: 200,
              lineHeight: '44px'
            }}
          >
            {title}
          </p>
        </div>
        <div className="prose text-neutral-black mb-8 whitespace-pre-line text-left font-thin">
          {description}
        </div>

        {pipe(
          image,
          O.fold(
            () => null,
            _image => {
              const contentfulImageData =
                getContentfulImageFromGatsbyData(_image)
              return (
                <div className="flex w-full flex-col items-center">
                  <ContentfulImage
                    classNameOverride="max-w-[500px]"
                    {...contentfulImageData}
                  />
                </div>
              )
            }
          )
        )}
      </div>
    </Modal>
  )
}

export default InfoModal
