import { gql } from '@apollo/client/index.js'

export const HERO_BANNER_QUERY = gql`
  query HeroBannerQuery($id: String!, $preview: Boolean!, $locale: String!) {
    heroBanner(id: $id, locale: $locale, preview: $preview) {
      __typename
      id: sys {
        id
      }
      contentCollection {
        items {
          ... on HeroBannerColumn {
            backgroundColor
            description {
              json
              links {
                entries {
                  block {
                    ... on QuoteWizard {
                      __typename
                      id: sys {
                        id
                      }
                      sys {
                        id
                      }
                      jebbitUrl
                      type
                      buttonText
                      ntExperiencesCollection {
                        items {
                          id: sys {
                            id
                          }
                          name: ntName
                          type: ntType
                          audience: ntAudience {
                            id: sys {
                              id
                            }
                            name: ntName
                          }
                          config: ntConfig
                          variants: ntVariantsCollection(limit: 40) {
                            items {
                              ... on QuoteWizard {
                                __typename
                                id: sys {
                                  id
                                }
                                sys {
                                  id
                                }
                                jebbitUrl
                                type
                                buttonText
                              }
                            }
                          }
                        }
                      }
                    }
                    ... on Button {
                      sys {
                        id
                      }
                      __typename
                      buttonText: text
                      url
                      type
                    }
                  }
                }
              }
            }
          }
          image {
            url
            description
            originalWidth: width
            originalHeight: height
          }
          imageTablet {
            url
            description
            originalWidth: width
            originalHeight: height
          }
          imageMobile {
            url
            description
            originalWidth: width
            originalHeight: height
          }
          textColor
          textAlignment
          contentVerticalAlignment
          linkColor
          hasPromoSticker
        }
      }
      floatingBadge
      hasPromoSticker
      isFullWidth
      heightType
      disclaimerText
      roundedCorners
    }
  }
`
