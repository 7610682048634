import type { documentSchema } from '@ecomm/contentful-schemas'
import { useCurrentPromoBannerData } from '@ecomm/promotions-hooks'
import type { V2HeroBannerSchema } from '@ecomm/shared-components'
import { V2HeroBanner } from '@ecomm/shared-components'
import { useMediaQuery } from '@ecomm/shared-hooks'
import type { TypeOf } from '@simplisafe/ewok'

import { modifyDescription } from './helper'

type DocumentSchema = TypeOf<typeof documentSchema>

export function HeroBanner({
  heroBannerProps,
  location = ''
}: {
  readonly heroBannerProps: V2HeroBannerSchema
  readonly location?: string
}) {
  const isDesktop = useMediaQuery('DesktopAndUp')

  const { heroStickerImage } = useCurrentPromoBannerData()

  const [heroBannerColumn] = [...heroBannerProps.contentCollection.items]
  const newDescription: DocumentSchema = modifyDescription(
    heroBannerColumn,
    location
  )

  const newHeroBannerColumn = {
    ...heroBannerColumn,
    description: {
      ...heroBannerColumn.description,
      json: newDescription
    }
  }

  const props = {
    ...heroBannerProps,
    contentCollection: {
      items: [newHeroBannerColumn]
    }
  }

  return (
    <V2HeroBanner
      {...props}
      className="prose-h1:mb-9 prose-h1:mt-9 prose-h1:max-w-xs prose-h1:font-arizona prose-h1:text-heading-1-size prose-h1:md:mt-1 prose-h1:lg:mt-9 prose-h1:lg:max-w-xl prose-h1:lg:text-[65px] prose-h1:lg:tracking-[-0.01em]"
      heroStickerImage={heroStickerImage}
      isFullWidth={isDesktop ? heroBannerProps.isFullWidth : true}
    />
  )
}
