import { graphql, useStaticQuery } from 'gatsby'

export const use2gUpgradePageQuery = () => {
  return useStaticQuery(graphql`
    #graphql
    query Form2gUpgradeQuery {
      form2gUpgrade: contentfulJson(
        contentful_id: { eq: "26rn7ujN8aiwQh6Ce36Ywx" }
      ) {
        ...json
      }
      sidNumberOverlayImage: contentfulAsset(
        contentful_id: { eq: "1fKicmCTwedbcooEE6xXSQ" }
      ) {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  `)
}
