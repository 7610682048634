import { logError } from '@ecomm/error-handling'
import { lead2gUpgrade } from '@simplisafe/ss-ecomm-data/simplisafe/leadGenClient'
import { navigate } from 'gatsby'

export type Form2GUpgradeBody = {
  readonly city: string
  readonly email: string
  readonly firstName: string
  readonly lastName: string
  readonly phoneNumber: string
  readonly sidNumber: string
  readonly state: string
  readonly streetAddress1: string
  readonly streetAddress2: string
  readonly zipCode: string
}

const useSubmit =
  () =>
  (values: Form2GUpgradeBody, setSubmitting: (value: boolean) => void) => {
    const handleFailure = (error: Error) => {
      setSubmitting(false)
      logError(error)
    }
    const handleSuccess = () => navigate('/mod-upgrade/2g-sunset/thank-you')

    lead2gUpgrade(values)(handleFailure)(handleSuccess)
  }

export default useSubmit
