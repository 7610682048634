import { gql } from '@apollo/client/index.js'
import { FOOTER_FRAGMENT, footerSchema } from '@ecomm/footer-data'
import { useContentful } from '@ecomm/shared-apollo'
import { useOdmonExperience } from '@ecomm/shared-ninetailed-odmon'

export const CITY_STATE_FOOTER_QUERY = gql`
  ${FOOTER_FRAGMENT}
  query CITY_STATE_FOOTER_QUERY($preview: Boolean!, $locale: String!, $id: String!) {
    footer(id: $id, locale: $locale, preview: $preview) {
      ...footer
    }
  }
`

const useFooterQuery = () => {
  const odmonExperienceFooterId = '5HfUvVfg5mVzZdML4GE6xe'
  const defaultFooterId = '2SCKVa13yRuLSU4GuooHlb'
  const isOdmonVariant = useOdmonExperience().isVariant
  const footerId = isOdmonVariant ? odmonExperienceFooterId : defaultFooterId
  const { data } = useContentful(CITY_STATE_FOOTER_QUERY, { id: footerId })

  return footerSchema.parse(data.footer)
}

export default useFooterQuery
