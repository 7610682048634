import {
  ContentfulRichText,
  contentfulRichTextSchema,
  GatsbyImage,
  GatsbyImageSchema
} from '@ecomm/shared-components'
import { TypeOf } from '@simplisafe/ewok'
import React from 'react'

export type MediaPageBannerProps = {
  readonly text: TypeOf<typeof contentfulRichTextSchema>
  readonly image: GatsbyImageSchema
}

export function MediaPageBanner({ text, image }: MediaPageBannerProps) {
  return (
    <section className="flex flex-col-reverse gap-4 overflow-hidden rounded-base bg-neutral-light-100 md:flex-row md:gap-8">
      <div className="my-auto p-4 md:w-1/2 md:p-8">
        <ContentfulRichText raw={text.raw} />
      </div>
      <GatsbyImage className="md:w-1/2" image={image} />
    </section>
  )
}
