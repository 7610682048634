import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { ContentfulImage } from '@ecomm/contentful/components'
import { ContentfulRichText } from '@ecomm/shared-components'
import classNames from 'classnames'

import { ContentfulImageQuerySchema } from '@ecomm/home-landing-data'
import { FinancingSmallTextSchema } from '@ecomm/home-landing-data'

const opts: Options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const data: ContentfulImageQuerySchema = node.data.target

      return (
        <ContentfulImage
          classNameOverride="w-[70px] md:w-[103px] lg:w-[180px] h-[28px] md:h-[41px] lg:h-[72px]"
          description={data.description}
          format="unset"
          originalHeight={data.file.details.image.height}
          originalWidth={data.file.details.image.width}
          quality={100}
          url={data.file.url}
        />
      )
    }
  }
}

export default function SmallText({
  text,
  internalTitle,
  iconPosition
}: FinancingSmallTextSchema) {
  return (
    <div
      className={classNames(
        'gap-4 mx-auto text-center prose-h2:font-default prose-h2:text-heading-3-size prose-h2:leading-h3-height prose-h2:mb-0 bottom-0.5 md:bottom-[10px] lg:bottom-[3px]',
        {
          'flex items-center': iconPosition === 'topcenter',
          'prose-p:text-neutral-dark text-sm':
            internalTitle === 'Financing - Legal Note'
        }
      )}
    >
      <ContentfulRichText {...text} optionsCustom={opts} />
    </div>
  )
}
