import { PriceProvider } from '@ecomm/data-price'

import PackageCardFinancing from './PackageCardFinancing'
import { PackageCardFinancingGridSchema } from '@ecomm/home-landing-data'

export default function PackageCardFinancingGrid({
  gridItems
}: PackageCardFinancingGridSchema) {
  return (
    <PriceProvider locale="en-US" skus={gridItems.map(i => i.product)}>
      <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-4">
        {gridItems.map((gitem, i) => (
          <PackageCardFinancing key={`grid-item-${i}`} {...gitem} />
        ))}
        <p className="col-span-1 lg:col-span-3 text-center mb-0">
          *With interactive monitoring
        </p>
      </div>
    </PriceProvider>
  )
}
