import { SelectChevron } from '@ecomm/checkout-icons'
import { useUniqueId } from '@ecomm/shared-hooks'
import { isSome } from 'fp-ts/lib/Option'
import { head } from 'fp-ts/lib/ReadonlyArray'
import { Link } from '@ecomm/framework'
import { useState } from 'react'

export type LinkProps = {
  readonly name: string
  readonly url: string
}

export type DropdownRedirectorProps = {
  readonly title: string
  readonly subtitle: string
  readonly links: readonly LinkProps[]
  readonly buttonName: string
}

export function DropdownRedirector({
  title,
  subtitle,
  links,
  buttonName
}: DropdownRedirectorProps) {
  const selectId = useUniqueId('DropdownRedirector')
  const firstLink = head(links)
  const [selectedUrl, setSelectedUrl] = useState(
    isSome(firstLink) ? firstLink.value.url : ''
  )

  const handleChange = (url: string) => {
    setSelectedUrl(url)
  }

  return (
    <div className="bg-neutral-light-50 px-10 py-10 lg:py-20">
      <div className="mx-auto w-full md:max-w-4xl">
        <h2 className="font-arizona m-0 text-[28px] font-bold text-neutral-black md:text-center lg:text-[55px]">
          {title}
        </h2>
        <div className="flex w-full flex-col items-center md:w-auto md:flex-row lg:justify-center">
          <div className="w-full lg:w-2/3">
            <label
              className="font-arizona m-0 mt-6 block text-lg font-bold text-neutral-black"
              htmlFor={selectId}
            >
              {subtitle}
            </label>
            <div className="relative">
              <select
                className="outline-primary rounded-base block w-full appearance-none border border-r-8 border-transparent p-4 py-[18px] text-lg font-normal text-neutral-black outline-1"
                id={selectId}
                onChange={event => handleChange(event.target.value)}
                value={selectedUrl}
              >
                {links.map(({ name, url }, index) => (
                  <option key={index} value={url}>
                    {name}
                  </option>
                ))}
              </select>
              <SelectChevron className="absolute right-4 top-1/2 -translate-y-1/2 transform" />
            </div>
          </div>
          {isSome(firstLink) && (
            <Link
              className="btn btn-solid-primary ml-6 mt-6 block w-full min-w-[190px] self-end !p-4 md:mt-0 md:w-auto"
              to={selectedUrl}
            >
              {buttonName}
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}
