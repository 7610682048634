import { HERO_BANNER_QUERY, heroBannerSchema } from '@ecomm/city-state-data'
import { useContentful } from '@ecomm/shared-apollo'
import { useMemo } from 'react'

export const useHeroBannerQuery = (bannerId: string) => {
  const { data: apolloData } = useContentful(HERO_BANNER_QUERY, {
    id: bannerId
  })
  const heroBannerData = useMemo(
    () => heroBannerSchema.parse(apolloData?.heroBanner),
    [apolloData]
  )
  return heroBannerData
}
