import { isNilOrEmpty } from '@simplisafe/ewok'
import { useField } from 'formik'

interface SelectInputProps {
  readonly label: string
  readonly name: string
  readonly options: readonly string[]
  readonly id: string
  readonly containerClassName?: string
  readonly placeholder?: string
}

function Form2GSelectInput({ label, options, ...props }: SelectInputProps) {
  const [field, meta] = useField(props)
  const error = meta.touched && meta.error
  return (
    <div className="flex-1">
      <label
        className="prose text-neutral-black whitespace-pre-line text-left font-medium"
        htmlFor={props.id}
      >
        {label}
      </label>
      <select
        className={`rounded-base block h-14 w-full max-w-[427px] border border-solid px-4 text-base font-normal leading-[1.56] first:mt-0 last:mb-0 md:text-lg md:leading-[1.56] ${
          error ? 'border-sale' : 'border-neutral-medium-100'
        }`}
        {...field}
        {...props}
      >
        {!isNilOrEmpty(props.placeholder) && (
          <option value="">{props.placeholder}</option>
        )}
        {!isNilOrEmpty(options)
          ? options.map((item: string) => (
              <option key={`${item}`} value={item}>
                {item}
              </option>
            ))
          : ''}
      </select>
      {error ? <div className="text-sale mt-1">{meta.error}</div> : null}
    </div>
  )
}

export default Form2GSelectInput
