import { ContentfulImage } from '@ecomm/contentful/components'
import { ContentfulRichText } from '@ecomm/shared-components'
import { useTracking } from 'react-tracking'

import { FinancingBannerSchema } from '@ecomm/home-landing-data'
import { trackShopNow } from '@ecomm/cdp-tracking'

export default function FinancingBanner({ content }: FinancingBannerSchema) {
  const { trackEvent } = useTracking()

  const trackOnClick = (buttonName: string) => () => {
    buttonName === 'button2' && trackShopNow()
    trackEvent({
      action: `${buttonName}_affirm_hero_click`,
      category: 'affirm_finance',
      event: `${buttonName}_affirm_hero_click`,
      label: ''
    })
  }

  return (
    <div className="prose-a:underline flex rounded-base overflow-hidden hover:prose-a:no-underline relative prose-headings:text-white prose-p:text-white prose-a:text-white items-center w-full flex-auto px-4 py-6 md:p-10 lg:p-16 min-h-[350px] md:min-h-[400px] lg:min-h-[650px]">
      {content.map((column, i) => (
        <div className="w-full" key={`hero-banner-column-${i}`}>
          <ContentfulImage
            classNameOverride="absolute -z-[1] w-full h-full left-0 top-0 !object-cover object-bottom"
            description={column.image.description}
            originalHeight={column.image.file.details.image.height}
            originalWidth={column.image.file.details.image.width}
            url={column.image.file.url}
          />
          <ContentfulImage
            classNameOverride="w-24 lg:w-36 mb-8"
            description={column.logo.description}
            originalHeight={column.logo.file.details.image.height}
            originalWidth={column.logo.file.details.image.width}
            url={column.logo.file.url}
          />
          <ContentfulRichText {...column.description} />
          <div className="flex flex-col md:flex-row gap-4">
            {column.description.references.map(r =>
              !r.url ? (
                <button
                  className="btn btn-solid-primary affirm-site-modal"
                  key={`btn-banner-affirm`}
                  onClick={trackOnClick('button1')}
                >
                  {r.text}
                </button>
              ) : (
                <a
                  className="btn btn-outlined !no-underline hover:border-white hover:bg-white hover:text-neutral-black"
                  href={r.url}
                  key={`btn-shop-now`}
                  onClick={trackOnClick('button2')}
                >
                  {r.text}
                </a>
              )
            )}
          </div>
        </div>
      ))}
    </div>
  )
}
