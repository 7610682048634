import { useMicroCopy } from '@ecomm/micro-copy'
import { BigChevronDown } from '@ecomm/shared-icons'
import classNames from 'classnames'
import { Link } from '@ecomm/framework'
import { useState } from 'react'

type City = {
  readonly name: string
  readonly url: string
}

function CitiesGroup({
  cities,
  label
}: {
  readonly cities: readonly City[]
  readonly label: string
}) {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(isOpen => !isOpen)

  const microCopy = useMicroCopy()

  const contentId = `citydropdown-${label.replace(/\s/g, '')}-content`

  return (
    <div
      className={classNames(
        'bg-neutral-light-50 mx-0 mt-5 rounded-2xl border-0 px-6 py-5 lg:px-10 lg:py-8',
        {
          'max-h-fit': isOpen,
          'max-h-28': !isOpen
        }
      )}
    >
      <button
        aria-controls={isOpen ? contentId : ''}
        aria-expanded={isOpen}
        className="flex w-full cursor-pointer justify-between border-0 bg-transparent p-0"
        onClick={toggle}
      >
        <h3
          className={classNames(
            'mt-0 grow text-left text-base font-bold lg:text-2xl lg:font-medium',
            {
              'mb-10 lg:mb-12': isOpen,
              'mb-0': !isOpen
            }
          )}
        >
          {label}
        </h3>
        <BigChevronDown
          className={classNames('h-6 w-6 transition-transform duration-200', {
            'rotate-180': isOpen,
            'rotate-0': !isOpen
          })}
          titleA11y={
            isOpen
              ? microCopy['a11y-close-section'](label)
              : microCopy['a11y-open-section'](label)
          }
        />
      </button>

      <div
        className={classNames(
          !isOpen
            ? 'h-0 scale-y-0 opacity-0'
            : 'h-auto scale-y-100 opacity-100',
          'transition-opacity duration-500'
        )}
        id={contentId}
      >
        <div
          className="columns-2 text-base font-bold leading-9 md:columns-3 lg:columns-5 lg:text-lg lg:font-normal lg:leading-9"
          data-component={`${label}_links`}
        >
          {cities.map((city, i) => (
            <Link
              className="text-neutral-black block hover:no-underline"
              key={`${city.name}-${i}`}
              to={city.url}
            >
              {city.name}
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

/**
 * Will split cities into arrays of cities, following the alphabetical division specified in the groups variable
 * and return the result.
 * We could place this in the gatsby-node.ts to avoid the computation clientside at the expense of having duplicated data.
 * Time complexity O(groups.length * cities.length), cities must be a sorted array
 *
 * @param groups Example: [['A', 'E'],['F', 'J'],['K', 'O'],['P', 'T'],['U', 'Z']]
 * @param cities Sorted array
 */
function parseCities(
  groups: readonly (readonly [string, string])[],
  cities: readonly City[]
) {
  return groups.reduce(
    (
      acc: readonly {
        readonly label: string
        readonly cities: readonly City[]
      }[],
      group,
      k
    ) => {
      const [start] = group
      const [end] = k < groups.length - 1 ? groups[k + 1] : [null]

      const i = cities.findIndex(c => {
        const [head] = c.name.toLowerCase().split('')
        return head >= start.toLowerCase()
      })
      const j = end
        ? cities.findIndex(c => {
            const [head] = c.name.toLowerCase().split('')
            return head >= end.toLowerCase()
          })
        : cities.length

      return [
        ...acc,
        {
          label: group.join(' - '),
          cities: cities.slice(i, j)
        }
      ]
    },
    []
  )
}

export type CityDropdownProps = {
  readonly title: string
  readonly cities: readonly City[]
  readonly groups: readonly (readonly [string, string])[]
}

export function CityDropdown({ title, cities, groups }: CityDropdownProps) {
  const citiesGroups = parseCities(groups, cities)

  return (
    <div>
      <h2 className="lg:leading-h1-height mb-2 text-[28px] leading-8 lg:mb-7 lg:text-[55px]">
        {title}
      </h2>
      {citiesGroups.map(group => (
        <CitiesGroup
          cities={group.cities}
          key={group.label}
          label={group.label}
        />
      ))}
    </div>
  )
}
