import {
  type Options,
  documentToReactComponents
} from '@contentful/rich-text-react-renderer'
import { MARKS } from '@contentful/rich-text-types'
import type { BenefitsText } from '@ecomm/city-state-data'
import React from 'react'

type Props = {
  readonly text: BenefitsText
  readonly location: string
}

export const benefitsTextRenderer = (location: string): Options => ({
  renderMark: {
    [MARKS.SUPERSCRIPT]: text => (
      <sup className="text-xs lg:text-[50%]">{text}</sup>
    )
  },
  renderText: text => text.replace('{{location}}', location)
})

export const BenefitsRichText = React.memo(({ text, location }: Props) => (
  <div
    className="prose-h2:mb-6 prose-h2:mt-0 prose-h2:font-arizona prose-h2:text-[28px] prose-h2:leading-h3-height prose-h3:mb-0 prose-h3:text-base prose-h3:font-bold prose-h3:leading-normal prose-p:mb-4 prose-p:mt-0 prose-p:text-base lg:prose-h2:mb-9 lg:prose-h2:text-[55px] lg:prose-h2:leading-h2-height lg:prose-h3:mb-1 lg:prose-h3:text-lg lg:prose-p:mb-5 lg:prose-p:text-lg"
    data-testid="benefits-rich-text"
  >
    {documentToReactComponents(text.text.json, {
      ...benefitsTextRenderer(location)
    })}
  </div>
))
