import type { documentSchema } from '@ecomm/contentful-schemas'
import { V2HeroBannerColumnSchema as HeroBannerColumnSchema } from '@ecomm/shared-components'
import { JSONObject, substituteVariable } from '@ecomm/utils'
import { TypeOf } from '@simplisafe/ewok'
type DocumentSchema = TypeOf<typeof documentSchema>

export const modifyDescription = (
  heroBannerColumn: HeroBannerColumnSchema,
  location: string
): DocumentSchema => {
  return <DocumentSchema>(
    (<unknown>(
      substituteVariable(
        <JSONObject>(<unknown>heroBannerColumn.description.json),
        'location',
        location
      )
    ))
  )
}
