import { type Region, locations } from '../../../../seo-locations/src'

export type City = {
  readonly name: string
  readonly lat: number
  readonly lng: number
  readonly url?: string
}

type StateNameToStateObj = {
  readonly [state: string]: {
    readonly cities: readonly City[]
    readonly dynamicCities: readonly City[]
    readonly state_id: string
    readonly lat: number
    readonly lng: number
    readonly region: Region
  }
}

export const stateDictionary = <StateNameToStateObj>locations

// For the Benefits component CTFL entry only. If we add more region content we have to change this code
export const regionContentId = {
  midAtlantic: '615atyBEGWhfQSdvLUjIQq',
  midWest: '4Uu0HQDsizyBizYn23vASW',
  mountain: '6TZ9DPGMMfJ1IOsnU6rpv9',
  newEngland: '6ZE9ykO3UYWm1VOJqyydUM',
  southEast: '6icr7r9QSawXaygd88K0rU',
  southwest: '48ypqOgODM8kVW92VRdYFc'
}

export const locationDisplayName = (
  state: string,
  stateId?: string,
  city?: string
) => {
  const cityWithStateId = stateId ? `${city}, ${stateId}` : city
  // If city is defined, so is stateId. Hence cityWithStateId is a string and here we apply the String constructor to it to avoid TS errors
  return city && city !== '' ? String(cityWithStateId) : state
}
