import { z } from '@simplisafe/ewok'
import { graphql, useStaticQuery } from 'gatsby'

//
const layoutSchema = z.object({
  layout: z.object({
    // this is temporary while we migrate to Apollo
    footer: z.object({
      contentful_id: z.string()
    })
  })
})

export const use2gUpgradeFooterQuery = (): string => {
  return layoutSchema.parse(
    useStaticQuery(graphql`
    #graphql
    query Footer2gUpgradeQuery {
      layout: contentfulLayout(
        contentful_id: { eq: "7MXykYxWepCkrZ7R52poqz" }
      ) {
        footer {
          contentful_id
        }
      }
    }
  `)
  ).layout.footer.contentful_id
}
