import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { GatsbyFooter as Footer } from '@ecomm/footer-components'
import { Header, useHeaderRedesignQuery } from '@ecomm/header'
import { parseJSONDataFromContentful } from '@ecomm/scout'
import * as O from 'fp-ts/lib/Option'
import { useState } from 'react'
import { z } from 'zod'

import Upgrade2gForm from '../../components/Form2GUpgrade/Upgrade2GForm'
import InfoModal from '../../components/Info/infoModal'
import { use2gUpgradeFooterQuery } from '../../hooks/use2gUpgradeFooterQuery'
import { use2gUpgradePageQuery } from '../../hooks/use2gUpgradePageQuery'

type Form2gUpgradeContentType = {
  readonly heading: string
  readonly description: string
  readonly formDescription: string
  readonly sidNumberOverlayTitle: string
  readonly sidNumberOverlayCopy: string
}

const Form2gUpgradeSchema = z.object({
  content: z.object({
    internal: z.object({
      content: z.string()
    })
  })
})

const Upgrade2gPageSchema = z.object({
  form2gUpgrade: Form2gUpgradeSchema,
  sidNumberOverlayImage: gatsbyImageSchema.optional()
})

type IUpgrade2gPageSchema = z.infer<typeof Upgrade2gPageSchema>

export default function Upgrade2gTemplate() {
  const [showInfoModal, setShowInfoModal] = useState(false)
  const footerId = use2gUpgradeFooterQuery()
  const headerData = useHeaderRedesignQuery()
  // @ts-expect-error - this needs a zod schema to change the type from unknown
  const queryData: IUpgrade2gPageSchema = use2gUpgradePageQuery()
  const data: Form2gUpgradeContentType = parseJSONDataFromContentful(
    queryData,
    'form2gUpgrade'
  )
  const infoModalImage = O.fromNullable(queryData?.sidNumberOverlayImage)
  return (
    <div>
      <Header {...headerData} />
      <div className="flex w-screen flex-col items-center p-4 md:p-8 lg:px-16 lg:pb-16">
        <div className="max-w-8xl mb-4 w-full">
          <h1 className="prose leading-20 mb-4 max-w-none whitespace-pre-line text-center text-5xl text-[65px] font-thin leading-[65px]">
            {data.heading}
          </h1>
          <div className="prose text-neutral-black rounded-base m-auto w-full overflow-hidden whitespace-pre-line px-4 py-8 text-center md:w-4/5 md:py-8 xl:w-[65%]">
            {data.description}
          </div>
          <div className="rounded-base bg-neutral-light-100 shadow-soft m-auto mb-4 flex w-full flex-col justify-center overflow-hidden px-4 py-8 md:w-4/5 md:py-8 xl:w-[65%]">
            <div className="prose text-neutral-black whitespace-pre-line px-4 text-left">
              <p>{data.formDescription}</p>
            </div>
            <Upgrade2gForm setShowInfoModal={setShowInfoModal} />
            <InfoModal
              description={data.sidNumberOverlayCopy}
              image={infoModalImage}
              isOpen={showInfoModal}
              onClose={() => setShowInfoModal(false)}
              title={data.sidNumberOverlayTitle}
            />
          </div>
        </div>
      </div>
      <Footer footerId={footerId} type="Full" />
    </div>
  )
}
