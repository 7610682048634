import { GatsbyFooter as Footer } from '@ecomm/footer-components'
import { BelowTheFold } from '@ecomm/lander'
import {
  ApplyPromoCode,
  PageToaster,
  PromoBannerWrapper
} from '@ecomm/promotions-components'
import { Header, useHeaderRedesignQuery } from '@ecomm/header'
import {
  CountryRedirectModal,
  PopupQuoteWizard
} from '@ecomm/shared-components'
import { TrackingProvider } from '@ecomm/tracking'
import type { Locale } from '@ecomm/utils'
import { graphql, type PageProps } from 'gatsby'

import { usePromoBannerExperimentQuery } from '../../experiments/PromoBannerPhoneNumber/usePromoBannerExperimentQuery'
import { useMediaPageQuery } from '../../hooks/useMediaPageQuery'
import { useMediaPageFragment } from './useMediaPageFragment'
import { MediaPageBanner, MediaTemplate } from '@ecomm/media-components'

export type PageContext = {
  readonly locale: Locale
}

export type Props<T> = Pick<
  PageProps<T, PageContext, unknown, Response>,
  'data' | 'pageContext'
>

export default function MediaPageTemplate<T>({ data }: Props<T>) {
  const { heroText, mediaPageTemplate, pageTitle } =
    //@ts-expect-error
    useMediaPageFragment(data)

  const { defaultHeroText, defaultHeroImage, popupWizard } = mediaPageTemplate

  //heroBanner is optional but defaultHeroBanner not. So, if heroBanner exists, it will be prioritized
  const bannerText = heroText || defaultHeroText

  const headerData = useHeaderRedesignQuery()
  const bodyContent = useMediaPageQuery()

  const promoBannerExperiment = usePromoBannerExperimentQuery()

  return (
    <TrackingProvider metaTitle={pageTitle}>
      <PageToaster />
      <ApplyPromoCode />
      <MediaTemplate>
        {{
          Content: (
            <main
              className="prose relative z-0 w-full whitespace-pre-line md:prose-md lg:prose-lg"
              id="content"
            >
              <div className="mx-auto mb-16 flex max-w-8xl flex-col gap-16 px-4 pt-8 md:px-8">
                <MediaPageBanner image={defaultHeroImage} text={bannerText} />
                <BelowTheFold data={bodyContent} />
                <CountryRedirectModal />
              </div>
              <PopupQuoteWizard data={{ ...popupWizard }} />
            </main>
          ),
          Header: <Header {...headerData} />,
          PromoBanner: (
            <PromoBannerWrapper
              experimentData={promoBannerExperiment}
              type="standard"
            />
          ),
          Footer: (
            <Footer
              footerId={mediaPageTemplate.footer.contentful_id}
              type="Full"
            />
          )
        }}
      </MediaTemplate>
    </TrackingProvider>
  )
}

export const query = graphql`
  query MediaPageTemplate($id: String) {
    contentfulMediaPageV2(id: { eq: $id }) {
      id
      contentful_id
      title
      pageTitle
      heroText {
        raw
      }
      mediaSource
      url
      mediaPageTemplate {
        ...mediaPageTemplateFragment
        ...mediaPageTemplateFragmentVariations
      }
    }
  }
`
