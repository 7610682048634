import { locationPageSchema } from '@ecomm/city-state-data'
import { useConfidenceBarQuery } from '@ecomm/lander'

import { graphql, useStaticQuery } from 'gatsby'

export const useLocationPageQuery = () => {
  return locationPageSchema.parse(
    useStaticQuery(graphql`
      fragment locationQWBaseFragment on ContentfulQuoteWizard {
        __typename
        id: contentful_id
        contentful_id # This is needed for QWs embedded in a rich text
        jebbitUrl
        type
        buttonText
      }

      fragment locationQWExperienceFragment on ContentfulNinetailedExperience {
        id: contentful_id
        name: nt_name
        type: nt_type
        audience: nt_audience {
          id: contentful_id
          name: nt_name
        }
        config: nt_config {
          components {
            baseline {
              id
            }
            variants {
              id
              hidden
            }
          }
          traffic
          distribution
        }
        variants: nt_variants {
          ... on ContentfulQuoteWizard {
            ...locationQWBaseFragment
          }
        }
      }
      query LocationPageQuery {
        heroBanner: contentfulHeroBanner(
          contentful_id: { eq: "79eORzoSFK2lAV3jFVbPC7" }
        ) {
          ...heroBannerCityStateFragment
        }
        confidenceBar: contentfulJson(
          contentful_id: { eq: "4MPjXZsXki3lB8IIfHPOau" }
        ) {
          content {
            internal {
              content
            }
          }
        }
        contentfulAffirmBanner(
          contentful_id: { eq: "4VmV1UBXepTJFhRn8xKvni" }
        ) {
          description {
            raw
          }
          title
          internal {
            type
          }
        }
        bestSystems: contentfulJson(
          contentful_id: { eq: "51eZ6K3wwQUfs4J3L5PFBP" }
        ) {
          content {
            internal {
              content
            }
          }
        }
        guaranteeCopy: contentfulJson(
          contentful_id: { eq: "5bXaWtcoGF04HVmWb5Kuoe" }
        ) {
          content {
            internal {
              content
            }
          }
        }
        guaranteeAsset: contentfulAsset(
          contentful_id: { eq: "5FRJNkZAaZL4mHk6vuMNVb" }
        ) {
          gatsbyImageData(placeholder: BLURRED)
          description
        }
        quoteWizard: contentfulQuoteWizard(
          contentful_id: { eq: "6wRdF53o74w2MhOhBIkzip" }
        ) {
          ...locationQWBaseFragment
          nt_experiences {
            ...locationQWExperienceFragment
          }
        }
        expertSectionAsset: contentfulAsset(
          contentful_id: { eq: "4l8DenaxSNBl1LH3mmDLMv" }
        ) {
          gatsbyImageData(placeholder: BLURRED)
        }
        comparisonTable: contentfulJson(
          contentful_id: { eq: "1zTyewofJ6lKWySS451GBg" }
        ) {
          content {
            internal {
              content
            }
          }
        }
        comparisonTableOdmonVariant: contentfulJson(
          contentful_id: { eq: "18qXWQrIu5vaqsUScRFNeI" }
        ) {
          content {
            internal {
              content
            }
          }
        }
        quoteWizardAsset: contentfulAsset(
          contentful_id: { eq: "7FApwfEA91pNmqu35jzIoy" }
        ) {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          description
        }
        seo: contentfulSeo(contentful_id: { eq: "3VlbNi0kqqpGCHhMF1JBVv" }) {
          metaKeywords
          metaTitle
          isNoindex: noIndex
          isNofollow: noFollow
          canonicalLink
          metaDescription {
            metaDescription
          }
        }
        dropdownRedirector: contentfulJson(
          contentful_id: { eq: "1EPQ5mfGWjFBpTWwkEIS0g" }
        ) {
          content {
            internal {
              content
            }
          }
        }
        linksGrid: contentfulJson(
          contentful_id: { eq: "2kzKC8GdPkEmM9lWhfFeHT" }
        ) {
          content {
            internal {
              content
            }
          }
        }
      }
    `)
  )
}

export const useStaticLocationData = () => {
  const confidenceBarData = useConfidenceBarQuery('4MPjXZsXki3lB8IIfHPOau')

  return {
    confidenceBarData
  }
}
