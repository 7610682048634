import { Breadcrumbs } from '@ecomm/header'
import { getCityPageLink, getStatePageLink } from '@ecomm/seo-locations'

export type BreadcrumbsLocationsProps = {
  readonly state: string
  readonly city?: string | null
  readonly isDynamicCityPage?: boolean
}

export function BreadcrumbsLocations({
  state,
  city = null,
  isDynamicCityPage = false
}: BreadcrumbsLocationsProps) {
  return (
    <Breadcrumbs
      steps={[
        ...[
          {
            label: 'Locations',
            slug: 'location'
          },
          {
            label: state,
            slug: getStatePageLink(state).slice(1)
          }
        ],
        ...(city
          ? [
              {
                label: city,
                slug: getCityPageLink(state, city, isDynamicCityPage).slice(1)
              }
            ]
          : [])
      ]}
      template="PDP"
    />
  )
}
