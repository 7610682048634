import { useField } from 'formik'

interface TextInputProps {
  readonly label: string
  readonly name: string
  readonly id: string
  readonly containerClassName?: string
  readonly placeholder?: string
}

function Form2GTextInput({ label, ...props }: TextInputProps) {
  const [field, meta] = useField(props)
  const error = meta.touched && meta.error
  return (
    <div className="flex-1">
      <label
        className="prose text-neutral-black whitespace-pre-line text-left font-medium"
        htmlFor={props.id}
      >
        {label}
      </label>
      <input
        className={`placeholder:text-neutral-medium-100 text-body-size placeholder:font-default placeholder:text-body-size rounded-base h-14 border border-solid p-4 ${
          error ? 'border-sale' : 'border-neutral-medium-100'
        } w-full`}
        {...field}
        {...props}
      />
      {error ? <div className="text-sale mt-1">{meta.error}</div> : null}
    </div>
  )
}

export default Form2GTextInput
