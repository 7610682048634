import { ContentfulImage } from '@ecomm/contentful/components'
import { usePriceContext } from '@ecomm/data-price'
import { AffirmPromoMessage } from '@ecomm/shared-components'
import { voidFn } from '@simplisafe/ewok'
import { window } from 'browser-monads-ts'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'

import { PackageCardFinancingSchema } from '@ecomm/home-landing-data'

export default function PackageCardFinancing({
  displayName,
  image,
  products,
  product,
  slug
}: PackageCardFinancingSchema) {
  const { getDiscountedPriceWithServicePlan } = usePriceContext()

  const price = pipe(
    getDiscountedPriceWithServicePlan(product),
    O.getOrElse(() => 0)
  )

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 md:grid-rows-2 lg:grid-rows-1 lg:grid-cols-1 bg-neutral-light-50 rounded-base overflow-hidden gap-5 text-center">
      <div className="col-span-1 mt-5 lg:mt-6 md:row-span-1 flex flex-col justify-end items-center">
        <h3 className="w-full mb-2">{displayName}</h3>
        <AffirmPromoMessage
          affirmClient={window.affirm}
          elementStyles="affirm-as-low-as text-base m-0 text-center"
          logoType="symbol"
          onLearnMoreClick={voidFn}
          pageType="category"
          price={price}
        />
      </div>
      <div className="relative col-span-1 md:row-span-2 lg:row-span-1 md:-mb-2">
        <ContentfulImage
          classNameOverride="w-full h-64 md:h-80 md:object-cover md:object-center"
          description={image.description}
          originalHeight={image.file.details.image.height}
          originalWidth={image.file.details.image.width}
          url={image.file.url}
        />
        {products ? (
          <span className="absolute bg-[#4FCBF7] rounded-lg left-2 px-4 py-1 top-2">
            {products.length} Pieces
          </span>
        ) : null}
      </div>
      <div className="col-span-1 mb-5 lg:mb-5 flex md:row-span-1 justify-center md:mb-0">
        <a
          className="btn block w-fit btn-outlined btn-outlined-primary h-fit"
          href={`/${slug}`}
        >
          Shop now
        </a>
      </div>
    </div>
  )
}
